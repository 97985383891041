import { gsap } from "gsap";

export function cooperate() {
    const el = document.querySelector('.gs-cooperate');
    
    const textWrapper1 = el.querySelector('.line1');
    textWrapper1.innerHTML = textWrapper1.textContent.replace(/\S/g, "<span class='char'>$&</span>");
    
    const textWrapper2 = el.querySelector('.line2');
    textWrapper2.innerHTML = textWrapper2.textContent.replace(/\S/g, "<span class='char'>$&</span>");
    
    const textWrapper3 = el.querySelector('.line3');
    textWrapper3.innerHTML = textWrapper3.textContent.replace(/\S/g, "<span class='char'>$&</span>");
  
    textWrapper1.style.visibility = 'visible';
    textWrapper2.style.visibility = 'visible';
    textWrapper3.style.visibility = 'visible';
    
    let chars1 = textWrapper1.querySelectorAll('.char')
    let chars2 = textWrapper2.querySelectorAll('.char')
    let chars3 = textWrapper3.querySelectorAll('.char')

    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: el,
        }
    });
    
    const cfrom = {
        y: -40,
        scaleY: -1,
        opacity: 0,
    }
    
    const cto = {
        y: 0,
        opacity: 1,
        stagger: 0.05,
        duration: 1.2,
        scaleY: 1,
        ease: 'elastic.out(1, .8)',
    }
    
    tl
    .fromTo(
        chars1, cfrom, cto, 
    )
    
    .fromTo(
        chars2, cfrom, cto, '-=1'
    )
    
    .fromTo(
        chars3, cfrom, cto, '-=1'
    )
}
