document.addEventListener('DOMContentLoaded', () => {
    window.scroll(0,0);

    const init = () => {
        document.documentElement.removeAttribute('style');
        //carousels();
        anims();

        setTimeout(() => {
            window.scroll(0,0)
            
        }, 250);  
    };

    window.addEventListener('load', init);

}, false);