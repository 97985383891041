import { gsap, Elastic, Power1, TweenMax, Power3 } from "gsap";

document.addEventListener('DOMContentLoaded', () => {

    const el = document.querySelectorAll('.js-accordion');

    window.accordion_it = (obj) => {
        let item = obj.querySelectorAll('.js-item'),
            tl;
            
        let mediaQuery = '(min-width: 941px)',
            mediaQueryList = window.matchMedia(mediaQuery);
  
        const toggle = function(ev, section, op) {
            tl = gsap.timeline({clearProps:"all"});
            
            tl.to(section, {
                height: ev,
                duration: .25,
                ease: Power1.easeOut
            })
            .fromTo(section, .5, {
                opacity: 0,
                y: 20,
                ease: 'elastic.out(1, .8)',
            }, {
                opacity: 1,
                y: 0,
                ease: 'elastic.out(1, .8)',
            })
        }

        const action = (e) => {
            const _that = e.currentTarget,
                  _height = 0;
        
            if (window.innerWidth <= 940) {
                if (_that.classList.contains('is-rollout')) {
                    _that.classList.remove('is-rollout');
                    toggle(_height, _that.getElementsByClassName('js-section')[0], 0);                
                    
                } else {
                    for (let j = 0; j < el.length; j++) {
                        let item_all = el[j].querySelectorAll('.js-item');
                        
                        for (let i = 0; i < item_all.length; i ++) {
                            item_all[i].classList.remove('is-rollout');
                            toggle(_height, item_all[i].getElementsByClassName('js-section')[0], 0);
                        } 
                    }
        
                    _that.classList.add('is-rollout');
                    toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
                }
            }
        };

        const start = () => {  
            for( let i = 0; i < item.length; i ++ ) {    
                item[i].addEventListener('click', action);
                if (item[i].classList.contains('is-rollout')) {
                    toggle('auto', item[i].getElementsByClassName('js-section')[0], 1);
                }
            }        
        }
        const destroy = () => {
            tl ? tl.kill(1,1) : false;

            for( let i = 0; i < item.length; i ++ ) {    
                item[i].removeEventListener('click', action);
                item[i].querySelector('.js-section').removeAttribute('style')
            }
        }
        start();
        
        mediaQueryList.addEventListener('change', event => {
            if (event.matches) {
                for( let i = 0; i < item.length; i ++ ) {
                    item[i].querySelector('.js-section').removeAttribute('style');
                }
            } else { }
        })
        
        if (mediaQueryList.matches) {
            for( let i = 0; i < item.length; i ++ ) {
                item[i].querySelector('.js-section').removeAttribute('style');
            }
        } else {
            
        }
    };

    
    if (el.length > 0) {
        for (let i = 0; i < el.length; i++) {
            accordion_it(el[i]);
        }
    }

}, false);