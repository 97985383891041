import { gsap } from "gsap";

export function fade() {
    gsap.utils.toArray(".gs-fade > *").forEach((section) => {
        gsap.from(section, {
            scrollTrigger: {
                trigger: section,
                start: '-50px bottom',
                end: 'bottom',
                toggleActions: "play complete complete reset",
            },
            autoAlpha: 0, 
            duration: .5,
            y: '50px'
        });
    });
}
