import { gsap } from "gsap";

export function rows() {
    gsap.utils.toArray(".gs-rows > *").forEach((section) => {
        gsap.from(section, {
            scrollTrigger: {
                trigger: section,
                toggleActions: "play complete complete complete",
                scrub: 1,
                start: 'top bottom',
                end: 'top center'
            },    
            opacity: 0,
            xPercent: -15,
        });
    });
}
