const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded', () => {
    
    const buttonOpen = document.querySelectorAll('.js-zencal-open'),
          buttonClose = document.querySelector('.js-zencal-close'),
          zencal = document.querySelector('.js-zencal');
    
    const init = () => {
        for (let i = 0; i < buttonOpen.length; i++) {
            buttonOpen[i].addEventListener('click', (e) => {
                document.documentElement.classList.add('zencal-opened');

                zencal.classList.add('is-block');
                setTimeout(() => {
                    zencal.classList.add('is-visible');
                }, 100)

                enableBodyScroll(zencal);                
                e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
            })
        }
        
        buttonClose.addEventListener('click', (e) => {
            zencal.classList.remove('is-visible');
            setTimeout(() => {
                zencal.classList.remove('is-block');
                document.documentElement.classList.remove('zencal-opened');
            }, 250)
            
            enableBodyScroll(zencal);
            
            e.preventDefault() ? e.preventDefault() : e.preventDefault = false;
        })
    }
    
    zencal ? init() : false;

}, false)
