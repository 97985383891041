import { gsap } from "gsap";

export function simple() {
    gsap.utils.toArray(".gs-simple").forEach((section) => {
        gsap.from(section, {
            scrollTrigger: {
                trigger: section,
                toggleActions: "play complete complete complete",
                scrub: 1,
                start: 'top bottom',
                end: 'top center'
            },      
            xPercent: 15,
        });
    });
}
