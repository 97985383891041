import css from './sass/style.scss';

require('./js/accordion')
require('./js/anims')
//require('./js/carousels')
//require('./js/cookieconsent')
require('./js/fonts')
require('./js/init')
require('./js/nav')
require('./js/polyfills')
require('./js/scrollpos')
require('./js/smoothscroll')
//require('./js/text-roller')
//require('./js/video')
require('./js/zencal')
//require('./js/anims/canvas')
//require('./js/ttt')
