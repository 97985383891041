import { gsap, Elastic } from "gsap";

export function wantmore() {
    gsap.from('.gs-wantomore_ninja', {
        scrollTrigger: {
            trigger: ".gs-wantmore",
            toggleActions: "play complete complete complete",
            scrub: 1,
            start: 'top center',
             end: 'top 10%'
        },      
        yPercent: 15,
        scale: .8
    });          
}
