document.addEventListener('DOMContentLoaded', () => {
    
    const el = document.querySelector('.js-topbar');
    
    let narrow = false;
    
    const action = () => {
        let scroll_pos = window.pageYOffset || window.scrollY,
            ww = window.innerWidth;

        if (scroll_pos > 0) {
	            el.classList.add('is-narrow');
        } else {
            el.classList.remove('is-narrow');
        }       
    }

    action();
    el ? window.addEventListener('scroll', action) : false;

}, false);
