import { gsap, Elastic } from "gsap";

export function hero() {
    
    const heroleads = document.querySelector('.gs-hero-leads'),
          video = document.querySelector('#videostart'),
          sourceElement = video.querySelector('source');
    let play_video = true;

    const queryString = window.location.search;        
    if (queryString) {
        const urlParams = new URLSearchParams(queryString),
              target = urlParams.get('video');
        if (target === '0') {
            play_video = false;
        } 
    }

    const showBlocks = () => {
        document.body.removeAttribute('style');
        document.documentElement.classList.add('after-intro');
        document.documentElement.classList.add('is-loaded');
        
        setTimeout(() => {
            document.querySelector('#cover').remove();
        }, 250);  

        let loader = gsap.timeline()
    
        .to(".shape", {
            attr: {d:"M 0 0.3 Q 0.5 -0.5 1 0.3 L 1 1 L 0 1 z"},
            duration: 0.5, 
            onComplete: () => {
            }
        })
        
        .to(".shape", {
            attr: {d:"M 0 1 Q 0.5 1.4 1 1 L 1 1 L 0 1 z"},
            duration: .8, 
            ease: "power1.out",
            //ease:'power1.in',
        }, '-=.1')
        
        .to(document.querySelector('.gs-topbar'), {
            autoAlpha: 1,
            duration: 1,
            ease: Elastic.easeOut.config(1, 0.3), 
            y: 0,
            //yPercent: 100,
        }, '-=.2')
        
        
        .from(heroleads.querySelector('.gs-first'), {
            duration: .9,
            autoAlpha: 0,
            ease: 'elastic.out(1, .6)',
            yPercent: -30,
        }, '-=1')
        .from(heroleads.querySelector('.gs-second'), {
            duration: .8,
            autoAlpha: 0,
            ease: 'elastic.out(1, .6)',
            xPercent: 10,
        }, '-=.8')
        .from(heroleads.querySelector('.c-breadcrumbs'), {
            duration: .7,
            autoAlpha: 0,
            ease: 'elastic.out(1, .8)',
            xPercent: -10,
        }, '-=.7')
        .from(heroleads.querySelector('.gs-third'), {
            duration: .7,
            autoAlpha: 0,
            ease: 'elastic.out(1, .8)',
            xPercent: -10,
        }, '-=.6')
        .from(heroleads.querySelector('.gs-fourth'), {
            duration: .7,
            autoAlpha: 0,
            ease: 'elastic.out(1, .8)',
            xPercent: 10,
        }, '-=.5')
        .from(heroleads.querySelector('hr'), {
            duration: .7,
            ease: 'elastic.out(1, .8)',
            scaleX: 0,
        }, '-=.4')
    }
    
    if (play_video === true) {
        const mediaQuery = '(max-width: 640px)',
              mediaQueryList = window.matchMedia(mediaQuery),
              desktop = video.dataset.desktop,
              mobile = video.dataset.mobile;
    
        mediaQueryList.addEventListener('change', event => {
            if (event.matches) {
                sourceElement.src = mobile;
                video.load();
            } else {
                sourceElement.src = desktop;
                video.load();
            }
        })
    
        if (mediaQueryList.matches) {
            sourceElement.src = mobile;
            video.load();
        } else {
            sourceElement.src = desktop;
            video.load();
        }
    
        video.addEventListener('ended', () => {
            showBlocks();
        })
    } else {
        showBlocks();
    }
}
